import { Text, TextAlign, TextColor, TextSize } from "shared/ui/Text/Text"
import cls from './Credits.module.scss'
import { classNames } from "shared/lib/classNames/classNames"

export const Credits = (props) => {
    const { mode, margin, className, style } = props

    const mods = {
        [cls.short]: mode === 'short',
        [cls.margin]: margin,
    }

    const onRedirect = () => {
        window.open('https://t.me/TagLife_Help')
    }

    return (
        <div style={style} className={classNames(cls.Credits, mods, [className])}>
            {mode !== 'short' ?
                <Text
                    className={cls.CreditsText}
                    color={TextColor.TERBLACK}
                    align={TextAlign.LEFT}
                    size={TextSize.XS}
                >
                    <div>Есть идеи по улучшению?</div>
                    <div onClick={onRedirect} className={cls.href}> Напиши нам об этом</div>
                </Text>
                : null}
            <Text
                className={cls.CreditsText}
                color={TextColor.TERBLACK}
                align={TextAlign.RIGHT}
                size={TextSize.XS}
            >
                TagLife v.1.7.1 © 2024
            </Text>
        </div>
    )
}